




















































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { IParticipant, PARTICIPANT } from '@/interface/participant';
import { IProject } from '@/interface/project';
import { GROUP } from '@/interface/group';
import { IOptionValue } from '@/utils/interface';
import * as xlsx from 'xlsx';
import InvitationSend from './components/InvitationSend.vue';
import ParticipantModal from '@/components/ParticipantModal.vue';
import TopShortcutLeft from '@/components/common/shortcut/TopShortcutLeft.vue';

interface registerGroupList extends IOptionValue {
  participants?: [];
}

interface IParticipantAddDetail extends IParticipant {
  invitationDetail?: boolean;
  checked?: boolean;
  projectTitles: [];
  groupTitles: [];
}

@Component({
  components: {
    TopShortcutLeft,
    InvitationSend,
    ParticipantModal,
  },
})
export default class Invitation extends Vue {
  pending: boolean = false;
  padding: boolean = false;
  status: PARTICIPANT.INVITATION_STATUS = PARTICIPANT.INVITATION_STATUS.ALL;
  page: number = 1;
  totalRow: number = 0;
  limitPage: number = 10;
  search: string = '';
  PARTICIPANT: any = PARTICIPANT;
  //participants: any = [];
  registerGroupList: IProject[] = [];
  invitationArray: IParticipantAddDetail[] = [];
  uploadFiles: any = null;
  excelExt: string[] = ['xls', 'xlsx'];
  tabIndex: number = 0;
  addList: IParticipant = PARTICIPANT.getInitParticipant();
  invitationCheckList: any[];
  categoryOptions: Array<IOptionValue> = [];
  fileReader: FileReader | null = null;
  successListThead: string[] = ['이름', '성별', '생년월일', '이메일', '휴대폰'];
  successList: [] = [];
  failList: [] = [];
  addedList: any[] = [];
  addField: string = '';
  addFields: any = {};
  tempAddListThead: any[] = [];
  tempAddList: any[] = [];
  GROUP: any = GROUP;
  emailHtml: string = '';
  categoryIndex: number = 0;
  invitationCategory: string[] = [];
  categoryAdd: string = '';
  sort: number = -1;
  selectParticipant: IParticipant = PARTICIPANT.getInitParticipant();
  participants: IParticipantAddDetail[] = [];

  $refs!: Vue['$refs'] & {
    groupId: HTMLElement;
    listName: HTMLElement;
    listEmail: HTMLElement;
    listBirthDate: HTMLElement;
    listMobile: HTMLElement;
    uploadInvitation: any;
    invitationSend: InvitationSend;
    participantModal: ParticipantModal;
  };

  constructor() {
    super();
    this.invitationCheckList = [];
  }

  created() {
    this.invitationCategory = this.$store.getters.getInvitationCategory;
    this.categoryOptions = this.invitationCategory.map((category) => {
      const text = category == 'ALL' ? '분류' : category;
      const value = category === 'ALL' ? '' : category;
      return {
        text,
        value,
      };
    });
    //this.invitationList();
    this.load();
  }

  /*get categoryOptions(): Array<IOptionValue> {
    return this.invitationCategory.map(category => {
      const text = category == 'ALL' ? '분류' : category;
      const value = category === 'ALL' ? '' : category;
      return {
        text, value
      }
    })
  }*/

  getStatusText(status) {
    return this.PARTICIPANT.INVITATION_OPTIONS.find((i) => status === i.value).text || '';
  }

  async load() {
    const category = this.invitationCategory[this.categoryIndex];
    const sendData = {
      status: this.status,
      page: this.page,
      limitPage: this.limitPage,
      search: this.search,
      sort: this.sort,
      category,
      categoryStatus: 'invitation',
    };
    this.pending = true;
    try {
      const { data } = await this.axios({
        url: `participant`,
        method: 'GET',
        params: sendData,
      });
      const { result, participants, total } = data;
      if (result) {
        this.totalRow = total;
        this.participants = participants.map((l: IParticipant) => {
          const { _id: findId } = l;
          return {
            ...l,
            checked: this.invitationCheckList.findIndex((i) => i._id === findId) > -1 ? findId : false,
            invitationDetail: false,
          };
        });
      }
    } catch (e) {
      console.error(e);
    }
    this.pending = false;
  }

  addFieldSave() {
    if (this.addField.replace(/ /gi, '') == '') {
      this.addField = '';
      return;
    }
    const keys = Object.keys(this.addFields);
    if (keys.indexOf(this.addField) > -1) {
      this.$toast.error(`${this.addField}은 이미 추가된 사항입니다.`);
      return;
    } else {
      this.addFields[this.addField] = '';
      this.addField = '';
    }
  }

  async resetAddListData() {
    this.addList = PARTICIPANT.getInitParticipant();
  }

  /*async invitationList(): Promise<void> {
      const category = this.invitationCategory[this.categoryIndex]
      try {
          const sendData = {
              status: this.status,
              page: this.page,
              limitPage: this.limitPage,
              search: this.search,
              sort: this.sort,
              category,
          }
          const {data} = await this.axios.get(`/participant/invitation/list`, {params: sendData});
         console.log(data.list);
          const {list, listTotal, projectList} = data;
          this.totalRow = listTotal;

          this.invitationArray = list.map((l: IParticipant) => {
              const {_id: findId} = l;
              return {
                  ...l,
                  checked: this.invitationCheckList.findIndex(i => i._id === findId) > -1 ? findId : false,
                  invitationDetail: false,
              }
          });
          this.registerGroupList = projectList;
      } catch (e) {
          console.log(e);
      }
  }*/

  async listAddVaildation() {
    try {
      const { name, email, mobile, birthDate, gender } = this.addList;
      if (name.length < 2) {
        this.$refs.listName.focus();
        this.$toast.error(`이름을 입력해주세요.`);
        return;
      } else if (birthDate.length !== 8) {
        this.$toast.error(`생년월일 8자리를 입력해주세요.`);
        this.$refs.listBirthDate.focus();
        return;
      } else if (this.$validation.emailCheck(email) === false) {
        this.$toast.error(`이메일 형식에 맞게 입력해주세요.`);
        this.$refs.listEmail.focus();
        return;
      } else if (this.$validation.mobileTelCheck(mobile) === false) {
        this.$toast.error(`휴대폰번호 형식에 맞게 입력해주세요.`);
        this.$refs.listMobile.focus();
        return;
      }

      for (let i = 0; i < this.addedList.length; i++) {
        const row = this.addedList[i];
        const { email: addedEmail } = row;
        if (addedEmail === email) {
          this.$toast.error(`추가된 리스트에 이미 추가된 이메일 주소입니다.`);
          this.$refs.listEmail.focus();
          return;
        }
      }

      const fieldArray = Object.keys(this.addFields);
      this.tempAddListThead = [
        { key: 'name', label: '이름' },
        { key: 'age', label: '나이' },
        { key: 'email', label: '이메일' },
        { key: 'mobile', label: '휴대폰' },
      ];

      for (let i = 0; i < fieldArray.length; i++) {
        const field = fieldArray[i];
        const value = this.addFields[field].replace(/ /gi, '');
        if (value === '') {
          this.$toast.error(`${field}를 입력해주세요.`);
          return;
        }
        this.tempAddListThead.push({ key: field, label: field });
      }

      this.tempAddList.push({
        name: name,
        age: this.replaceAge(birthDate) + '세',
        email: email,
        mobile: mobile,
        ...this.addFields,
      });

      await this.resetAddListData();

      const pushData: IParticipant = {
        name: name,
        email: email,
        gender: gender,
        birthDate: birthDate,
        mobile: mobile,
        addFields: {
          ...this.addFields,
        },
        memos: [],
        invitationFavorite: false,
        participantFavorite: false,
        invitationGroups: [],
        invitationCategory: '',
        participantCategory: '',
      };
      this.addedList.push(pushData);
    } catch (e) {
      console.log(e);
    }
  }

  async listAdd() {
    try {
      const sendData = {
        registerUserId: this.$store.getters.getUserObjectId,
        list: this.addedList,
      };
      //console.log(this.addedList);
      this.padding = true;
      const { data } = await this.axios.post(`/participant/invitation/add`, sendData);
      this.padding = false;
      const { result, failList } = data;
      if (result === true && this.addedList.length !== failList.length) {
        this.$toast.success(`추가된 리스트${this.$validation.replaceComma(this.addedList.length)}가 저장되었습니다.`);
      }
      if (failList.length > 0) {
        this.tempAddListThead.push({ key: 'message', label: '실패사유' });
        this.addedList = failList;
        this.tempAddList = failList.map((list: any) => {
          const { name, birthDate, email, mobile, addFields, message } = list;
          return {
            name: name,
            age: this.replaceAge(birthDate) + '세',
            email: email,
            mobile: mobile,
            message: message,
            ...addFields,
          };
        });
      } else {
        await this.load();
        this.closeListAddModal();
      }
    } catch (e) {
      console.log(e);
    }
  }

  openListAddModal() {
    this.uploadFiles = null;
    this.addedList = [];
    this.addField = '';
    this.addFields = [];
    this.successList = [];
    this.failList = [];
    this.tempAddList = [];
    this.tempAddListThead = [];
    this.resetAddListData();
    this.$bvModal.show('listAddModal');
  }

  closeListAddModal() {
    this.$bvModal.hide('listAddModal');
  }

  async fileCheck() {
    if (!this.uploadFiles) {
      this.uploadFiles = null;
      return;
    }

    const { name } = this.uploadFiles;
    const [fileName, ext] = name.split('.');
    if (this.excelExt.indexOf(ext) === -1) {
      this.uploadFiles = null;
      this.$refs.uploadInvitation.files = [];
      this.$refs.uploadInvitation.fileName = [];
      this.$toast.error(`[${this.excelExt.join(', ')}]  파일만 업로드 가능합니다.`);
      return;
    }

    const readData = await this.readerExcelData(this.uploadFiles, 0);
    const sendData = {
      readData: readData,
    };
    this.padding = true;
    const { data } = await this.axios.post(`/participant/invitation/uploadDataCheck`, sendData);
    this.padding = false;
    const { successList, failList } = data;
    this.successList = successList;
    this.failList = failList;
    if (failList.length > 0) this.$toast.error(` ${failList.length}개 업로드 실패 이유를 확인해주세요.`);
    if (successList.length > 0) this.$toast.success(` ${successList.length}개 리스트가 저장 가능합니다.`);
  }

  async readerExcelData(excelFile: File, sheetIndex: number) {
    return new Promise((resolve, reject) => {
      if (this.fileReader === null) this.fileReader = new FileReader();
      this.fileReader.onload = (e: any) => {
        const data = e.target.result;
        const workBook = xlsx.read(data, { type: 'binary' });
        const sheetName = workBook.SheetNames[sheetIndex];
        const readData = xlsx.utils.sheet_to_json(workBook.Sheets[sheetName]);
        resolve(readData);
      };
      this.fileReader.readAsBinaryString(excelFile);
    });
  }

  replaceMobile() {
    this.addList.mobile = this.$validation.replaceOnlyNumber(this.addList.mobile);
  }

  replaceGender(gender: PARTICIPANT.GENDER): string {
    return gender == PARTICIPANT.GENDER.MAN ? '남자' : '여자';
  }

  replaceAge(date: string = ''): number {
    const today = new Date();
    const birthDate = new Date(date.substr(0, 4));
    return today.getFullYear() - birthDate.getFullYear() + 1;
  }

  //checkTd 클릭했을떄
  invitationChoice(e: any) {
    const { target }: { target: HTMLElement } = e;
    const checkClassName = 'invitationChoice';
    const checkClassName2 = 'checkTd';
    const { className } = target;

    if (className.indexOf(checkClassName) > -1 || className.indexOf(checkClassName2) > -1) {
      const checkBox: any = target.querySelector('input');
      const { value } = checkBox;
      const index = this.participants.findIndex((i) => i._id === value);
      this.participants[index].checked = !this.participants[index].checked ? value : false;
      this.invitationCheck(this.participants[index].checked, value);
    }
  }

  //checkTd 변경일때
  changeInvitationChoice(index: number) {
    const row = this.participants[index];
    const { checked, _id } = row;
    this.invitationCheck(checked, _id as string);
  }

  /**
   * 선택된 리스트 추가하고 삭제하기
   * @param checked
   * @param _id
   */
  invitationCheck(checked: any, _id: string): void {
    if (checked === false) {
      const findIndex = this.invitationCheckList.findIndex((i) => i._id === _id);
      this.invitationCheckList.splice(findIndex, 1);
    } else {
      const checkInvitation = this.participants.find((i) => i._id === _id);
      this.invitationCheckList.push(checkInvitation);
    }
  }

  replaceGroupDetailInfo(group: any, index: number): string {
    const user = this.participants[index];
    const { groupTitle, projectObjectId, info, _id } = group;
    const { status } = info[0];
    const { projectTitles, name } = user;
    const findProject = projectTitles.find((g) => g['_id'] == projectObjectId) || { title: '' };
    const { title: projectTitle } = findProject as { title: string };
    const statusText = this.PARTICIPANT.INVITATION_OPTIONS.find((i: any) => status === i.value).text || '';
    return `[${projectTitle}]${groupTitle} / ${statusText} `;
  }

  async uploadSave(gubun: string) {
    const sendData = {
      uploadData: gubun === 'success' ? this.successList : this.failList,
    };
    this.padding = true;
    const { data } = await this.axios.post(`/participant/invitation/uploadSave`, sendData);
    const { result, successList, failList } = data;
    this.padding = false;
    if (failList.length > 0) {
      this.$toast.error(` ${failList.length}개 업로드 실패 이유를 확인해주세요.`);
      this.failList = failList;
    }

    if (successList.length > 0) {
      this.$toast.success(`${successList.length} 리스트가 추가되었습니다.`);
      await this.load();
    }
    if (gubun == 'success') this.successList = [];
    if (successList.length === sendData.uploadData.length && failList.length < 1) this.closeListAddModal();
  }

  openInvitationModal() {
    if (this.invitationCheckList.length < 1) {
      this.$toast.error(`초대하실 대상자를 선택해주세요.`);
      return;
    }
    this.$refs.invitationSend.registerGroupObjectId = '';
    this.$refs.invitationSend.openInvitationModal();
  }

  //초대하기 선택취소
  cancelInvitation(listId: string) {
    const index = this.participants.findIndex((i) => i._id === listId);
    if (index > -1) this.participants[index].checked = false;
  }

  openCategoryModal() {
    this.categoryIndex = 0;
    this.categoryAdd = '';
    this.$bvModal.show('categoryAddModal');
  }

  categoryIndexChange(e: any) {
    const category = this.invitationCategory[this.categoryIndex] || 'ADD';
    this.load();
    //this.invitationList();
  }

  async categorySave() {
    try {
      if (this.categoryAdd.length < 3) {
        this.$toast.error(`분류이름을 입력해주세요.`);
        return;
      }
      const sendData = {
        category: this.categoryAdd,
      };
      this.padding = true;
      const { data } = await this.axios.post(`/user/invitationCategoryAdd`, sendData);
      const { result } = data;
      this.padding = false;
      if (result === true) {
        this.categoryIndex = 0;
        this.$toast.success(`초대하기 구분이 추가되었습니다.`);
        await this.$store.dispatch('verify');
        this.invitationCategory = this.$store.getters.getInvitationCategory;
        this.$bvModal.hide('categoryAddModal');
        this.categoryOptions = this.invitationCategory.map((category) => {
          return {
            text: category === 'ALL' ? '분류' : category,
            value: category === 'ALL' ? '' : category,
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async favoriteChange(index: number) {
    try {
      const item = this.participants[index];
      const { _id, name, invitationFavorite } = item;
      this.padding = true;
      const { data } = await this.axios.put(`/participant/invitation/favoriteChange/${_id}`);
      const { result } = data;
      this.padding = false;
      if (result === true) {
        this.$toast.success(`[${name}]님을 즐겨찾기  ${invitationFavorite ? '취소' : '등록'}하였습니다.`);
        await this.load();
      }
    } catch (e) {
      console.log(e);
    }
  }

  favoriteClass(index: number): string[] {
    const classArray = ['fa-star', 'fa-lg'];
    const item = this.participants[index];
    const { invitationFavorite } = item;

    if (invitationFavorite) classArray.push('fa');
    else classArray.push('far');
    return classArray;
  }

  sortChange() {
    this.sort = this.sort * -1;
    this.page = 1;
    this.load();
  }

  async changeCategory(index: number) {
    const row: IParticipant = this.participants[index];
    const { _id, invitationCategory, name } = row;
    this.padding = true;
    const { data } = await this.axios.put(`/participant/changeCategory/invitationCategory/${_id}`, {
      category: invitationCategory,
    });
    const { result } = data;
    this.padding = false;
    if (result === true) {
      this.$toast.success(`[${name}] 참여자의 분류가 (${invitationCategory})로 변경되었습니다.`);
    }
  }

  invitationSampleDownload() {
    location.href = '/file/invitation-sample.xlsx';
  }

  participantModalOpen(index: number) {
    this.selectParticipant = this.participants[index];
    this.$refs.participantModal.open();
  }
}
